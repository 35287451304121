import React, { useEffect, useState } from 'react'
import { SCContainer } from './styles'

const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  // Show button when page is scorlled upto given distance
  const toggleVisibility = (): void => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <SCContainer>
      {isVisible && (
        <div onClick={scrollToTop} className="scroll-btn" role="button" tabIndex={0}>
          <i className="fas fa-chevron-up" />
        </div>
      )}
    </SCContainer>
  )
}

export default ScrollToTop
