import React from 'react'
import { SCContainer } from './styles'

const FloatingWhatsapp: React.FC = () => {
  return (
    <SCContainer
      href="https://api.whatsapp.com/send?phone=51901422305&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PlayU."
      className="whatsapp-container"
      target="_blank"
      rel="noreferrer"
    >
      <i className="fab fa-whatsapp whatsapp-icon" />
      <span>¿Preguntas? ¡Escr&iacute;benos!</span>
    </SCContainer>
  )
}

export default FloatingWhatsapp
