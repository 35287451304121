import React from 'react'
import LogoPlayUImg from 'assets/images/logo_playu_black-big.png'
import { slide as Menu } from 'react-burger-menu'
import { SCContainer } from './styles'

interface NavBarProps {
  id: string
  menu: {
    label: string
    url?: string
  }[]
}

const NavBar: React.FC<NavBarProps> = ({ menu, id }: NavBarProps) => {
  return (
    <SCContainer>
      <div className="NavBar__Overlap" id={id} />
      <nav className="NavBar__Fixed">
        <div className="Desktop">
          <ul>
            <li className="logo">
              <a href="/">
                <img src={LogoPlayUImg} alt="PlayU" />
              </a>
            </li>
            {menu.map(({ label, url = '#' }) => (
              <li className="nav-option" key={label}>
                <a href={url}>{label}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="Mobile">
          <div className="burger">
            <Menu
              className="Menu_Mobile"
              pageWrapId="page-wrap"
              outerContainerId="outer-container"
              customBurgerIcon={<i className="fas fa-bars" />}
            >
              <ul>
                {menu.map(({ label, url = '#' }) => (
                  <li key={label}>
                    <a href={url}>{label}</a>
                  </li>
                ))}
              </ul>
            </Menu>
          </div>
          <div className="logo">
            <img src={LogoPlayUImg} alt="PlayU" />
          </div>
        </div>
      </nav>
    </SCContainer>
  )
}

export default NavBar
