import PremiumTemplate01Img from 'assets/images/template_premium_1.png'
import PremiumTemplate03Img from 'assets/images/template_premium_3.png'
import EnterpriseTemplate01Img from 'assets/images/template_enterprise_1.png'

import EnterpriseBenefit01Img from 'assets/images/enterprise-benefits/benefit_1.png'
import EnterpriseBenefit02Img from 'assets/images/enterprise-benefits/benefit_2.png'
import EnterpriseBenefit03Img from 'assets/images/enterprise-benefits/benefit_3.png'
import EnterpriseBenefit04Img from 'assets/images/enterprise-benefits/benefit_4.png'
import EnterpriseBenefit05Img from 'assets/images/enterprise-benefits/benefit_5.png'
import EnterpriseBenefit06Img from 'assets/images/enterprise-benefits/benefit_6.png'

import { SocialMedia } from 'utils/constants'

export const SocialMediaList = [
  {
    case: SocialMedia.Facebook,
    icon: 'fab fa-facebook-f',
    link: 'https://www.facebook.com/playu.shop',
  },
  {
    case: SocialMedia.Instagram,
    icon: 'fab fa-instagram',
    link: 'https://instagram.com/playu.shop',
  },
  {
    case: SocialMedia.Youtube,
    icon: 'fab fa-youtube',
    link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
  },
]

export const TemplateImagesList = [
  {
    imglink: PremiumTemplate01Img,
    link: 'templates/professional/t-bone/',
  },
  {
    imglink: PremiumTemplate03Img,
    link: 'templates/professional/t-black/',
  },
  {
    imglink: EnterpriseTemplate01Img,
    link: 'templates/enterprise/t-01/',
  },
]

export const NavMenuList = [
  {
    label: 'Inicio',
    url: '#home',
  },
  {
    label: 'Estilos',
    url: '#styles',
  },
  {
    label: 'Comprar',
    url: '#buy',
  },
]

export const EnterpriseBenefitsList = [
  {
    order: 1,
    title: 'Logo personalizado',
    icon: 'far fa-copyright',
    description: 'Coloca el logo de tu empresa y personaliza los colores.',
    url: EnterpriseBenefit01Img,
  },
  {
    order: 2,
    title: 'Redes sociales',
    icon: 'fas fa-bullseye',
    description: 'Comparte las Redes Sociales corporativas.',
    url: EnterpriseBenefit02Img,
  },
  {
    order: 3,
    title: 'Contacto',
    icon: 'fas fa-user-tie',
    description: 'Facilita tu información de contacto a tus clientes: teléfono, correo, ubicación.',
    url: EnterpriseBenefit03Img,
  },
  {
    order: 4,
    title: 'Servicios',
    icon: 'fas fa-tasks',
    description: 'Describe los servicios que brinda la empresa.',
    url: EnterpriseBenefit04Img,
  },
  {
    order: 5,
    title: 'Networking',
    icon: 'fas fa-network-wired',
    description: 'Genera conexiones, tus clientes o contactos pueden guardar tu tarjeta digital.',
    url: EnterpriseBenefit05Img,
  },

  {
    order: 6,
    title: 'Compartir',
    icon: 'fas fa-share-alt',
    description: 'Puedes compartirlo de forma ilimitada a través de tu código QR o link.',
    url: EnterpriseBenefit06Img,
  },
]
