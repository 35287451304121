import styled from '@emotion/styled'
import { PaletteColors, ViewPorts } from 'assets/theme'

export const SCContainer = styled.div`
  & .scroll-btn {
    position: fixed;
    bottom: 90px;
    right: 20px;
    z-index: 10;
    background: ${PaletteColors.turquoise};
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;

    & i {
      color: ${PaletteColors.white} !important;
    }
  }

  @media (min-width: ${ViewPorts.mdMin}) {
    & .scroll-btn {
      bottom: 112px;
      right: 42px;
    }
  }
`
