import React, { useState } from 'react'
import {
  SCMainTitle,
  SCBenefit,
  SCContainer,
  SCImage,
  SCDescription,
  SCButton,
  SCTitle,
  SCIcon,
  SCLeft,
  SCCenter,
  SCRight,
} from './styles'

interface Benefit {
  order: number
  title: string
  icon?: string
  description: string
  url: string
}

interface EnterpriseBenefitsProps {
  title?: string
  benefits: Benefit[]
}

const EnterpriseBenefits: React.FC<EnterpriseBenefitsProps> = ({
  title: mainTitle,
  benefits,
}: EnterpriseBenefitsProps) => {
  const [selected, setSelected] = useState<Benefit>(benefits[0])

  const firstHalf = benefits.slice(0, Math.round(benefits.length / 2))
  const secondHalf = benefits.slice(firstHalf.length)

  const handleOnClick = (newOrder: Benefit): void => {
    setSelected(newOrder)
  }

  return (
    <>
      {mainTitle && <SCMainTitle>{mainTitle}</SCMainTitle>}
      <SCContainer>
        <SCLeft data-aos="fade-right" data-aos-delay="0.2s">
          {firstHalf.map(benefit => {
            const { order, title, description, icon } = benefit
            return (
              <SCBenefit
                className="benefit"
                isSelected={order === selected.order}
                key={title}
                onClick={() => handleOnClick(benefit)}
                role="button"
                tabIndex={0}
              >
                <SCTitle className="title">
                  <span>{title}</span>
                  <SCIcon className={icon} isSelected={order === selected.order} />
                </SCTitle>
                <SCDescription>{description}</SCDescription>
              </SCBenefit>
            )
          })}
        </SCLeft>

        <SCCenter data-aos="fade-up" data-aos-delay="0.2s">
          <SCImage src={selected.url} />
        </SCCenter>

        <SCRight data-aos="fade-left" data-aos-delay="0.2s">
          {secondHalf.map(benefit => {
            const { order, title, description, icon } = benefit

            return (
              <SCBenefit
                className="benefit"
                isSelected={order === selected.order}
                key={title}
                onClick={() => handleOnClick(benefit)}
                role="button"
                tabIndex={0}
              >
                <SCTitle className="title">
                  <span>{title}</span>
                  <SCIcon className={icon} isSelected={order === selected.order} />
                </SCTitle>
                <SCDescription>{description}</SCDescription>
              </SCBenefit>
            )
          })}
        </SCRight>
      </SCContainer>

      <SCButton
        className="btn btn-primary"
        type="button"
        onClick={e => {
          e.preventDefault()
          window.open('templates/enterprise/t-01/', '_blank')
        }}
      >
        <span>Ver en Vivo</span>
        <i className="!tw-text-white tw-ml-1 fa-solid fa-arrow-up-right-from-square" />
      </SCButton>
    </>
  )
}

export default EnterpriseBenefits
