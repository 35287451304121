/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useEffect, useState } from 'react'
import Head from 'shared/components/Head/Head'
import Footer from 'components/templates/premium/t/Sections/Footer'
import PaymentsImg from 'assets/images/payments.png'
import NavBar from 'components/page/landing/NavBar'
import ScrollToTop from 'components/page/landing/ScrollToTop'
import MainLogoImg from 'assets/images/logo_horizontal.png'
import EnterpriseBenefits from 'components/page/landing/Enterprise/Benefits'
import FloatingWhatsapp from 'components/page/landing/FloatingWhatsapp'
import { Player } from '@lottiefiles/react-lottie-player'
import initJs from './init'
import { EnterpriseBenefitsList, NavMenuList, TemplateImagesList, SocialMediaList } from './constants'
import { SCIntroSection, SCYoutubeSection, SCEnterpriseSection, SCPreloader, SCBuySection } from './styles'
import './styles.css'
import 'aos/dist/aos.css'

const renderBuyButton = (isDesktop: boolean): ReactElement => {
  const handleClick = (): void => {
    window.open(
      'https://api.whatsapp.com/send?phone=51901422305&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PlayU.',
      '_blank',
      'noreferrer'
    )

    // window.open('https://5f1e-181-66-150-78.ngrok.io/auth/glogin', '_blank', 'noreferrer')
  }
  return (
    <div className={`buy-now ${isDesktop ? 'desktop' : 'mobile'} `}>
      <button className="buy-now" type="button" onClick={handleClick}>
        Comprar Ahora
      </button>
    </div>
  )
}

export const Home: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 1000)
  }, [])

  useEffect(() => {
    if (isLoaded) {
      initJs()
    }
  }, [isLoaded])

  return (
    <>
      <Head title="PlayU | Tarjeta de presentación digital" bodyClass={['main']} hidePrefix />
      {!isLoaded ? (
        <SCPreloader>
          <div className="preloader-body">
            <div className="cssload-container">
              <div className="cssload-speeding-wheel" />
            </div>
            <p>Cargando...</p>
          </div>
        </SCPreloader>
      ) : (
        <>
          <NavBar menu={NavMenuList} id="home" />

          <div className="page">
            <SCIntroSection className="tw-mb-12">
              <div className="container">
                <div className="grid">
                  <div>
                    <div data-aos="fade-down" data-aos-delay="200">
                      <img className="logo" src={MainLogoImg} alt="PlayU" />
                      <h3>Tu tarjeta de negocios digital</h3>
                      <p>Comparte tu informaci&oacute;n de contacto muy f&aacute;cilmente</p>
                      {renderBuyButton(true)}
                    </div>
                  </div>

                  <div>
                    <Player
                      className="video-player"
                      autoplay
                      loop
                      src="https://assets4.lottiefiles.com/private_files/lf30_4bVja9.json"
                    />
                  </div>

                  {renderBuyButton(false)}
                </div>
              </div>
            </SCIntroSection>
            <SCEnterpriseSection className="text-center tw-mb-12" id="enterprise">
              <div className="container tw-mb-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-header" data-aos="fade-down" data-aos-delay="200s">
                      <h3>¿Qu&eacute; es una tarjeta de negocios digital?</h3>
                      <div className="description">
                        <p>
                          Es una herramienta que te permite compartir tu informaci&oacute;n profesional y la de tu
                          empresa para crecer en tu mercado y generar conexiones con potenciales clientes.
                        </p>
                        <p>¡Mira lo que tenemos para ti!</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <EnterpriseBenefits benefits={EnterpriseBenefitsList} />
                  </div>
                </div>
              </div>
            </SCEnterpriseSection>

            <SCYoutubeSection className="container tw-mb-12">
              <div className="mobile-title">¿Cómo funciona PlayU?</div>
              <div className="video-url" data-aos="fade-right" data-aos-delay="0.2s">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/D0R0CwzDzrc"
                  title="PlayU Intro"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="video-description" data-aos="fade-left" data-aos-delay="0.2s">
                <div className="title">¿C&oacute;mo funciona PlayU?</div>
                <div className="desc">
                  <div className="number">1</div>
                  <div className="text">Elige el estilo que m&aacute;s te guste</div>
                </div>
                <div className="desc">
                  <div className="number">2</div>
                  <div className="text">Configuramos tu tarjeta digital</div>
                </div>
                <div className="desc">
                  <div className="number">3</div>
                  <div className="text">Impresiona y aumenta tus potenciales clientes</div>
                </div>
              </div>
            </SCYoutubeSection>

            <div className="nav-section text-center" id="styles">
              <div className="container tw-mb-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-header" data-aos="fade-down" data-aos-delay="200s">
                      <h2>ESTILOS</h2>
                      <div className="text-1">&quot;La primera impresi&oacute;n es la que cuenta&quot;</div>
                    </div>
                  </div>
                </div>
              </div>

              <section className="carousel-section section section-relative tw-mb-12">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 text-block-1" data-aos="fade-right">
                      <div className="section-sm section-sm-3">
                        <div className="tw-text-center md:tw-text-left container-titles tw-mb-2">
                          <h5>Professional</h5>
                          <h5>Desde S/29.90</h5>
                        </div>
                        <ol className="tw-text-left">
                          <li>&#x25ba; Elige el estilo que m&aacute;s te guste.</li>
                          <li>&#x25ba; Comparte tus redes sociales.</li>
                          <li>&#x25ba; Comparte tus servicios profesionales.</li>
                          <li>&#x25ba; Obt&eacute;n tu enlace personalizado.</li>
                          <li>&#x25ba; &Uacute;salo ilimitadamente.</li>
                          <li>
                            Comparte tu perfil a trav&eacute;s de tu c&oacute;digo QR o simplemente un enlace.
                            ¡T&uacute; eliges!
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-section">
                  <div
                    className="swiper swiper-slider swiper-slider-2"
                    data-next=".custom-swiper-button-next"
                    data-prev=".custom-swiper-button-prev"
                    data-loop="true"
                  >
                    <div className="swiper-wrapper">
                      {TemplateImagesList.map(template => (
                        <div key={template.imglink} className="swiper-slide">
                          <div className="swipe-floating-image" data-slide-bg={template.imglink} />
                          <div className="content" data-url={template.link} role="button" tabIndex={0}>
                            <button className="btn btn-primary" type="button" data-link={template.link}>
                              <span>Ver en Vivo</span>
                              <i className="tw-ml-1 !tw-text-white fa-solid fa-arrow-up-right-from-square" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="swiper-pagination" />
                  </div>
                  <div className="custom-swiper-button-wrap">
                    <div className="custom-swiper-button-next fa fa-arrow-right" />
                    <div className="custom-swiper-button-prev fa fa-arrow-left" />
                  </div>
                </div>
              </section>
            </div>

            <SCBuySection className="buy-section nav-section" id="buy">
              <div className="container text-center">
                <div className="row tw-mb-6">
                  <div className="col-md-12">
                    <div className="section-header" data-aos="fade-down" data-aos-delay="200">
                      <p className="line-one" />
                      <h2>COMPRAR</h2>
                      <div className="text-1">¡Impresiona m&aacute;s, imprime menos!</div>
                      <p className="line-one" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-sm-6 col-6 tw-mb-3">
                    <div className="single-contact text-center" data-aos="fade-down" data-aos-delay="400">
                      <i className="fas fa-phone" />
                      <h2>Whatsapp</h2>
                      <p>+(51) 901 422 305</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 tw-mb-3">
                    <div className="single-contact text-center" data-aos="fade-down" data-aos-delay="600">
                      <i className="far fa-envelope" />
                      <h2>Email</h2>
                      <p>hola@playu.pe</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 tw-mb-3">
                    <div className="single-contact text-center" data-aos="fade-down" data-aos-delay="800">
                      <i className="fab fa-gg" />
                      <h2>Redes Sociales</h2>
                      <div className="socials">
                        {SocialMediaList.map(sm => (
                          <a key={sm.link} href={sm.link} target="_blank" rel="noreferrer">
                            <i className={sm.icon} />
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 tw-mb-3">
                    <div className="single-contact text-center" data-aos="fade-down" data-aos-delay="1000">
                      <i className="fas fa-home" />
                      <h2>Ubicaci&oacute;n</h2>
                      <p>Lima, Per&uacute;</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <b className="tw-block tw-mb-2 tw-mt-4">Aceptamos</b>
                <img src={PaymentsImg} width="200" height="40" alt="Medios de Pago" />
              </div>
            </SCBuySection>
          </div>

          <Footer inverted />

          <ScrollToTop />
          <FloatingWhatsapp />
        </>
      )}
    </>
  )
}
