import Aos from 'aos'
import Swiper, { SwiperOptions, Pagination, Autoplay, Navigation } from 'swiper'

let $: any

if (typeof document !== `undefined` || typeof window !== `undefined`) {
  $ = require('jquery')
  // eslint-disable-next-line import/newline-after-import
  ;(window as any).jQuery = $
}

const addActiveSlideButtonClickEventListener = (): void => {
  const onBtnClick = (e: any): void => {
    const link = e.target.closest('button').getAttribute('data-link')
    window.open(link, '_blank')
  }

  const contentEl = document.querySelector('.swiper-slide-active .content button')
  if (contentEl) {
    contentEl.removeEventListener('click', onBtnClick)
    contentEl.addEventListener('click', onBtnClick)
  }
}

const initJs = (): null => {
  if (typeof document === `undefined` || typeof window === `undefined`) {
    return null
  }

  const plugins = {
    swiper: document.querySelectorAll('.swiper'),
  }

  if (plugins.swiper) {
    for (let i = 0; i < plugins.swiper.length; i += 1) {
      const sliderMarkup = plugins.swiper[i]
      const autoplayAttr = sliderMarkup.getAttribute('data-autoplay') || 5000
      const slides = sliderMarkup.querySelectorAll('.swiper-slide')
      const pagSelector: any = sliderMarkup.getAttribute('data-pagination')
      const pagNode = document.querySelector(pagSelector) || sliderMarkup.querySelector('.swiper-pagination')

      const navNextSelector = sliderMarkup.getAttribute('data-next')
      const navPrevSelector = sliderMarkup.getAttribute('data-prev')
      const navNextNode = $(sliderMarkup).parent().find(navNextSelector)[0]
      const navPrevNode = $(sliderMarkup).parent().find(navPrevSelector)[0]

      const options: SwiperOptions = {
        loop: sliderMarkup.getAttribute('data-loop') === 'true' || false,
        effect: (sliderMarkup.getAttribute('data-effect') as any) || 'slide',
        direction: (sliderMarkup.getAttribute('data-direction') as any) || 'horizontal',
        speed: sliderMarkup.getAttribute('data-speed') ? Number(sliderMarkup.getAttribute('data-speed')) : 1000,
        runCallbacksOnInit: false,
        slidesPerView: 'auto',
        modules: [Pagination, Autoplay, Navigation],
      }

      options.preventClicks = false

      if (Number(autoplayAttr)) {
        options.autoplay = {
          delay: Number(autoplayAttr),
        }
      }

      if (sliderMarkup.getAttribute('data-keyboard') === 'true') {
        options.keyboard = {
          enabled: sliderMarkup.getAttribute('data-keyboard') === 'true',
          onlyInViewport: true,
        }
      }

      if (sliderMarkup.getAttribute('data-mousewheel') === 'true') {
        options.mousewheel = {
          releaseOnEdges: true,
          sensitivity: 0.1,
        }
      }

      if (navPrevNode || navNextNode) {
        options.navigation = {
          nextEl: navNextNode,
          prevEl: navPrevNode,
        }
      }

      if (pagNode) {
        options.pagination = {
          el: pagNode,
          type: 'bullets',
          clickable: true,
        }
      }

      if (sliderMarkup.querySelector('.swiper-scrollbar')) {
        options.scrollbar = {
          el: '.swiper-scrollbar',
          hide: true,
          draggable: true,
        }
      }

      for (let s = 0; s < slides.length; s += 1) {
        const url = slides[s].querySelector('.swipe-floating-image')!.getAttribute('data-slide-bg')
        if (url) {
          ;(slides[s].querySelector('.swipe-floating-image') as any)!.style.backgroundImage = `url(${url})`
        }
      }

      setTimeout(() => {
        const sw = new Swiper((plugins as any).swiper[i], options)

        addActiveSlideButtonClickEventListener()
        sw.on('slideChange', () => setTimeout(addActiveSlideButtonClickEventListener, 0))
      }, 1000)
    }
  }

  // AOS
  Aos.init({
    once: true,
    easing: 'ease-in-out',
  })

  // Fix to load owl carousel correctly,
  // this setTimeout fixes are common when dealing with jquery and react at the same page
  setTimeout(() => Aos.refresh(), 1000)

  return null
}

export default initJs
