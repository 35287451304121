import styled from '@emotion/styled'
import { PaletteColors, ViewPorts } from 'assets/theme'

export const SCMainTitle = styled.div`
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 12px;
`

export const SCContainer = styled.div`
  display: flex;
  position: relative;

  & > div {
    flex: 1;
  }

  @media (max-width: ${ViewPorts.smMax}) {
    flex-direction: column;
  }
`

export const SCLeft = styled.div`
  text-align: right;

  .title {
    justify-content: flex-end;

    i {
      margin-left: 8px;
    }
  }

  @media (max-width: ${ViewPorts.smMax}) {
    text-align: left;

    .title {
      justify-content: flex-start;
    }
  }
`

export const SCCenter = styled.div`
  padding: 0 20px;

  @media (max-width: ${ViewPorts.smMax}) {
    display: none;
  }
`

export const SCRight = styled.div`
  text-align: left;

  .title {
    flex-direction: row-reverse;
    justify-content: flex-end;

    i {
      margin-right: 8px;
    }
  }

  @media (max-width: ${ViewPorts.smMax}) {
    .title {
      flex-direction: row;
      justify-content: flex-start;

      i {
        margin-left: 8px;
        margin-right: 0px;
      }
    }
  }
`

interface SCBenefitProps {
  isSelected: boolean
}

export const SCBenefit = styled.div<SCBenefitProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
  margin: 40px 0;
  min-height: 150px;
  border-radius: 10px;
  background-color: ${({ isSelected }) => (isSelected ? PaletteColors.turquoise : PaletteColors.white)};
  color: ${({ isSelected }) => (isSelected ? PaletteColors.white : 'inherit')};

  @media (max-width: ${ViewPorts.smMax}) {
    margin-top: 0px;
    margin-bottom: 30px;
    background-color: ${PaletteColors.white};
    color: inherit;
  }
`

interface SCImageProps {
  src: string
}

export const SCButton = styled.button`
  margin-top: 24px;

  @media (max-width: ${ViewPorts.smMax}) {
    display: none !important;
  }
`

export const SCImage = styled.div<SCImageProps>`
  width: 100%;
  height: 100%;
  min-height: 630px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ${({ src }) => (src ? `background-image:  url("${src}");` : '')};

  @media (max-width: 991px) {
    width: 280px;
    height: 560px;
    background-size: contain;
  }
`

export const SCTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`

export const SCIcon = styled.i<SCBenefitProps>`
  font-size: 30px;
  color: ${({ isSelected }) => (isSelected ? PaletteColors.white : PaletteColors.turquoise)} !important;

  @media (max-width: ${ViewPorts.smMax}) {
    color: ${PaletteColors.turquoise} !important;
  }
`

export const SCDescription = styled.div`
  font-size: 14px;
`
