import styled from '@emotion/styled'
import { PaletteColors, ViewPorts } from 'assets/theme'

const MobileHeight = '60px'
const DesktopHeight = '72px'

export const SCContainer = styled.div`
  & .NavBar__Overlap {
    height: ${MobileHeight};
  }

  & .NavBar__Fixed {
    position: fixed;
    z-index: 100;
    width: 100%;
    background: ${PaletteColors.white};
    top: 0;
    box-shadow: 5px 4px 10px #8f8f8f;
    height: ${MobileHeight};
  }

  & .Desktop {
    display: none;

    & ul {
      display: flex;

      & li {
        flex: 1;
        text-align: center;

        font-size: 16px;
        text-transform: uppercase;
        /* border-left: 1px solid #c4c4c4; */

        &.logo {
          flex: 0 0 150px;
          display: flex;
          justify-content: center;
          align-items: center;

          & a {
            padding: 0px;

            & img {
              width: 48px;
              height: 48px;
            }
          }
        }

        &.nav-option {
          & a {
            transition: none;
            padding: 24px 6px;
            display: block;
            color: #9b9b9b;

            &:hover,
            &:active {
              height: 71px;
              background: #f9f9f9;
              color: ${PaletteColors.turquoise};
            }
          }
        }
      }
    }
  }

  & .Mobile {
    display: flex;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    height: 60px;

    & .Menu_Mobile {
      width: 270px !important;
    }

    & .logo {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        margin-left: -42px;
        width: 36px;
        height: 36px;
      }
    }

    & .burger {
      flex: 0 0 42px;
      position: relative;
      display: flex;
      align-items: center;

      & > div {
        width: 100%;
        text-align: center;
      }

      & .bm-icon {
        font-size: 24px;
        color: #9b9b9b !important;
      }

      /* Color shape of burger icon bars on hover*/
      & .bm-burger-bars-hover {
        background: #a90000;
      }

      /* Position and sizing of clickable cross button */
      & .bm-cross-button {
        display: none;
      }

      /* Styling of overlay */
      & .bm-overlay {
        top: 60px;
        background: rgba(0, 0, 0, 0.3);
      }

      & .bm-menu-wrap {
        position: fixed;
        height: 100%;
        top: 60px;

        /* General sidebar styles */
        & .bm-menu {
          width: 100%;
          background: ${PaletteColors.white};
          font-size: 1.15em;

          /* Wrapper for item list */
          & .bm-item-list {
            color: #b8b7ad;

            /* Individual item */
            & .bm-item {
              & a {
                display: block;
                text-align: left;
                color: #9b9b9b;
                padding: 18px 6px;
                text-transform: uppercase;

                &:hover,
                &:active {
                  background: ${PaletteColors.turquoise};
                  color: ${PaletteColors.white};
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: ${ViewPorts.mdMin}) {
    & .NavBar__Overlap {
      height: ${DesktopHeight};
    }

    & .NavBar__Fixed {
      height: ${DesktopHeight};
    }

    & .Mobile {
      display: none;
    }

    & .Desktop {
      display: block;
    }
  }
`
