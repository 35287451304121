import styled from '@emotion/styled'
import { PaletteColors, ViewPorts } from 'assets/theme'

export const SCContainer = styled.a`
  width: 60px;
  height: 60px;
  bottom: 15px;
  right: 15px;
  position: fixed;
  background-color: #25d366;
  color: ${PaletteColors.white};
  border-radius: 50px;
  text-align: center;
  font-size: 18px;
  box-shadow: 2px 2px 3px #999;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  &:visited,
  &:hover {
    color: ${PaletteColors.white} !important;
  }

  span {
    display: none;
    margin-left: 12px;
  }

  & .whatsapp-icon {
    color: ${PaletteColors.white} !important;
    font-size: 30px;
  }

  @media (min-width: ${ViewPorts.mdMin}) {
    width: 300px;
    height: 60px;
    bottom: 40px;
    right: 36px;

    span {
      display: block;
    }

    & .whatsapp-icon {
      font-size: 42px;
    }
  }
`
