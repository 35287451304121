import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { PaletteColors, ViewPorts } from 'assets/theme'

export const SCIntroSection = styled.div`
  height: auto;
  padding: 30px 0;
  background-color: #f5f5f5;

  & .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    text-align: center;

    & .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;
      justify-items: center;
      align-items: center;

      & .logo {
        width: 280px;
      }

      & .video-player {
        width: 280px;
        height: 320px;
      }

      & .buy-now {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
        }

        & button {
          color: ${PaletteColors.white};
          font-size: 18px;
          line-height: 23px;
          background: ${PaletteColors.turquoise};
          border-radius: 100px;
          padding: 17px 74px 16px;
          white-space: nowrap;
        }
      }
    }
  }

  @media (min-width: ${ViewPorts.mdMin}) {
    padding: 60px 0;
  }

  @media (min-width: ${ViewPorts.ldMin}) {
    height: calc(100vh - 72px);
    padding: 0;

    & .container {
      text-align: left;

      & .grid {
        grid-template-columns: 1fr 1fr;
        grid-gap: 48px;

        & .logo {
          width: 360px;
        }

        & .video-player {
          width: 560px;
          height: 600px;
        }

        & .buy-now {
          &.desktop {
            display: block;
          }

          &.mobile {
            display: none;
          }

          & button {
            margin-top: 30px;
          }
        }
      }
    }
  }
`

export const SCYoutubeSection = styled.div`
  flex-direction: column;

  & .mobile-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 14px;
  }

  & .video-url {
    flex: 2;
    margin-bottom: 36px;

    & iframe {
      border: 0;
      border-radius: 20px;
      width: 100%;
      height: 315px;
    }
  }

  & .video-description {
    flex-basis: 500px;
    font-size: 22px;

    & .title {
      display: none;
      margin-bottom: 30px;
      font-weight: 600;
    }

    & .desc {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 76px 1fr;
      margin-bottom: 30px;
      font-weight: 400;

      & .number {
        color: ${PaletteColors.white};
        background: #000;
        width: 76px;
        height: 76px;
        border-radius: 50%;
        box-shadow: 6px 10px 25px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .text {
        font-size: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
      }
    }
  }

  @media (min-width: 768px) {
    & .video-url {
      & iframe {
        height: 420px;
      }
    }
  }

  @media (min-width: 1023px) {
    padding-top: 40px;
    display: flex;
    flex-direction: row;

    & .mobile-title {
      display: none;
    }

    & .video-url {
      margin-bottom: 0px;

      & iframe {
        width: 600px;
        height: 400px;
      }
    }

    & .video-description {
      flex-basis: 300px;

      & .title {
        display: block;
      }

      & .desc {
        & .text {
          text-align: left;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    & .video-description {
      font-size: 32px;
      flex-basis: 450px;

      & .desc {
        margin-bottom: 50px;

        & .text {
          font-size: 28px;
        }
      }
    }
  }

  @media (min-width: 1400px) {
    & .video-url {
      & iframe {
        width: 750px;
        height: 550px;
      }
    }

    & .video-description {
      flex-basis: 450px;
    }
  }
`

export const SCShareBySection = styled.div`
  margin-bottom: 42px;

  & .share-by-section {
    & .medias {
      color: ${PaletteColors.turquoise};
    }

    @media (max-width: 767px) {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }

  & .playu-tags {
    & .title {
      color: ${PaletteColors.turquoise};
    }

    @media (min-width: 600px) {
      .title {
        right: initial;
      }
    }
  }
`

export const SCEnterpriseSection = styled.div`
  margin-bottom: 42px;

  h3 {
    font-weight: 600;
    margin-bottom: 42px !important;
  }

  & .description {
    margin-top: 6px;
    margin-bottom: 48px;

    & p {
      margin-bottom: 9px;
    }
  }
`

const loaderAnimated = keyframes`
100% {
  transform: rotate(360deg);
}
`

export const SCPreloader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: ${PaletteColors.white};
  transition: 0.3s all ease;

  &.loaded {
    opacity: 0;
    visibility: hidden;
  }

  & .preloader-body {
    text-align: center;

    & .cssload-container {
      width: 100%;
      height: 36px;
      text-align: center;

      & .cssload-speeding-wheel {
        width: 36px;
        height: 36px;
        margin: 0 auto;
        border: 3px solid ${PaletteColors.turquoise};
        border-radius: 50%;
        border-left-color: transparent;
        border-bottom-color: transparent;
        animation: ${loaderAnimated} 0.88s infinite linear;
      }
    }

    & p {
      position: relative;
      right: -8px;
    }
  }
`

export const SCBuySection = styled.div`
  & .row {
    & .single-contact {
      height: 100%;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      word-break: break-word;

      & h2 {
        margin-top: 6px;
      }

      & .socials {
        padding-top: 6px;

        & i {
          margin: 0 10px;
          font-size: 14px;

          &i:hover {
            cursor: pointer;
          }
        }
      }

      & p {
        margin-top: 6px;
      }
    }
  }
`
